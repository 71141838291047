import clientAxios from './axios';

const tokenAuth = token => {
    if(token){
        // clientAxios.defaults.headers.common['x-auth-token'] = token;
        clientAxios.defaults.headers.common['X-Api-Key'] = token;
    }else{
        // delete clientAxios.defaults.headers.common['x-auth-token'];
        delete clientAxios.defaults.headers.common['X-Api-Key'];
    }
}

export default tokenAuth;