import React, { useReducer } from 'react';

import ThingsContext from './thingsContext';
import ThingsReducer from './thingsReducer';

import clientAxios from "../../config/axios";

import { GET_THINGS, GET_HISTORIES_THINGS } from '../../types';

const ThingsState = props => {


    const initialState = {
        //                                                  //Este es solo para saber como usar los initial state, pero
        //                                                  //      se puede quitar una ves que lo entienda.
        form: true,
        things: [],
        thingsHistory: [],
        loading: true
    }

    //                                                      //Dispatch para ejecutar las acciones.
    const [state, dispatch] = useReducer(ThingsReducer, initialState)

    //                                                      //Obtiene los things relacionados.
    const getThings = async () => {
        try {
            const response = await  clientAxios.get('/api/v1/things');
            
            dispatch({
                type: GET_THINGS,
                payload: response.data.things
            })

        } catch (error) {
            console.error(error);
        }
    }
    

    const getHistoryThing = async (_id) => {
        try {
            // const _id = "5efeaaf039a03d0b4128a448";
            // console.log('Cargando...');
            
            const response = await  clientAxios.get('/api/v1/thingshistory/'+_id);
            // console.log('Se otuvo');

            dispatch({
                type: GET_HISTORIES_THINGS,
                payload: response.data.thingsHistory
            })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <ThingsContext.Provider
            //                                              //Se pasan los vaalores del State inicial.
            value={{
                form: state.form,
                things: state.things,
                thingsHistory: state.thingsHistory,
                loading: state.loading,
                getThings,
                getHistoryThing
            }}
        >
            {props.children}
        </ThingsContext.Provider>
    )
}

export default ThingsState;