import React, { useRef, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ChartamLine = ({ thingsHistory }) => {
  //                                                        //Se crea un state para saber en que momento poder mostrar
  //                                                        //    la gráfica.
  const [hideChart, setHideChart] = useState(false);

  //                                                        /Se instancia la gráfica
  const chart = useRef(null);
  let xyLineChart;
  let data = [];

  const fieldsChart = [{
    name: "Potencia Activa",
    units: "W",
    value: "potency"
  },
  {
    name: "Factor de potencia",
    units: "%",
    value: "powerFactor"
  },
  {
    name: "Tensión",
    units: "V",
    value: "mediumTension"
  },
  {
    name: "Corriente",
    units: "A",
    value: "totalCurrent"
  },
  {
    name: "Caudal",
    units: "l/s",
    value: "flow"
  },
  {
    name: "Presión",
    units: "psi",
    value: "pressure"
  },
  {
    name: "Energía Activa",
    units: "KWh",
    value: "activeEnergy"
  },
  {
    name: "Nivel Hidrostático",
    units: "m",
    value: "hydrostaticLevel"
  }
  ]

  //@ts-check
  const formatDate = (dateString) => {
    // const options: Intl.DateTimeFormatOptions = {
    //   //Typescript ways of adding the type
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   second: "numeric",
    // };
    /*** Nueva fecha ***/
    const date = new Date(dateString);
    const hoursAdd = 6;
    date.setHours(date.getHours() + hoursAdd);
    return date.toUTCString();

    // return new Date(dateString).toLocaleString();//;([], options); //e.g 31/7/2020 11:44:42
    // return new Date(dateString).toUTCString();//;([], options); //e.g 31/7/2020 11:44:42 Anterior
    // return new Date(dateString).toDateString();//;([], options); //e.g 31/7/2020 11:44:42
    // return new Date(dateString).toDateString();//;([], options);//e.g Fri Jul 31 2020
    // return new Date(dateString).toLocaleDateString([], options);
  };


  /**
   * Se crea la información que se mostrara en la gráfica.
   *    obteniendola thingsHistory y regresa el objeto Data
   *    para la gráfica.
   */
  const createData = () => {
    // let data = [];

    //                                                      //Se obtiene el array de la fecha que envio el sensor.
    const arrayDates = thingsHistory.map((th) => formatDate(th.creationDate));
    //                                                      //1.Potency
    const arrayPotency = thingsHistory.map((th) => th.potency);
    //                                                      //2.Power Factor
    const arrayPowerFactor = thingsHistory.map((th) => th.powerFactor);
    //                                                      //3.Medium Tension
    const arrayMediumTension = thingsHistory.map((th) => th.mediumTension);
    //                                                      //4.Total Current
    const arrayTotalCurrent = thingsHistory.map((th) => th.totalCurrent);
    //                                                      //5.Flow
    const arrayFlow = thingsHistory.map((th) => th.flow);
    //                                                      //6.Pressure
    const arrayPressure = thingsHistory.map((th) => th.pressure);
    //                                                      //7.Active Energy
    const arrayActiveEnergy = thingsHistory.map((th) => th.activeEnergy);
    //                                                      //8.Hydrostatic Level
    const arrayHydrostaticLevel = thingsHistory.map((th) => th.activeEnergy);

    //                                                      //Se genera un for para agregarlos al data, creo que esto se
    //                                                      //    puede omitir pero por ahora se dejara.
    for (let i = 0; i < arrayDates.length; i++) {
      data.push({

        date: new Date(arrayDates[i]),
        // name: "name" + i,
        potency: arrayPotency[i], // * 2,
        powerFactor: arrayPowerFactor[i],
        mediumTension: arrayMediumTension[i],
        totalCurrent: arrayTotalCurrent[i],
        flow: arrayFlow[i],
        pressure: arrayPressure[i],
        activeEnergy: arrayActiveEnergy[i],
        arrayHydrostaticLevel: arrayActiveEnergy[i]

      });
      // console.log('Fecha: '+data[i].date+ "presión: "+data[i].pressure)
    }

    return data;
  };

  //                                                        //Crea la grafica
  const createChart = () => {
    xyLineChart = am4core.create("chartdiv", am4charts.XYChart);
    xyLineChart.paddingRight = 20;

    createSeries();

    xyLineChart.legend = new am4charts.Legend();
  };

  //                                                        //Se crea las series que se van agregar.
  const createSeries = () => {
    xyLineChart.data = createData();

    //                                                      //Se crea el axes
    let dateAxis = xyLineChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.tooltipDateFormat = "yyyy-MM-dd HH:mm:ss";

    //                                                      //Create value axis Y FECHA
    let valueAxis = xyLineChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;


    let series;

    fieldsChart.forEach(fields => {
      series = xyLineChart.series.push(new am4charts.LineSeries())
      series.dataFields.dateX = "date"
      series.dataFields.valueY = fields.value
      series.tooltipText = fields.name + ": [bold]{valueY}[/] " + fields.units
      series.strokeWidth = 1
      series.name = fields.name
      series.tensionX = 0.8
      series.bullets.push(new am4charts.CircleBullet())
    });

    /*
    //                                                      //Creando la serie 1 - TENSIÓN
    let series = xyLineChart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "pressure";
    series.tooltipText = columnName_1 + ": [bold]{valueY}[/] " + units_1;
    series.strokeWidth = 1;
    series.name = columnName_1;
    series.tensionX = 0.8;
    series.bullets.push(new am4charts.CircleBullet());

    //                                                      //Creando la serie 2 - CORRIENTE
    let series2 = xyLineChart.series.push(new am4charts.LineSeries());
    series2.dataFields.dateX = "date";
    series2.dataFields.valueY = "value";
    series2.tooltipText = columnName_2 + ": [bold]{valueY}[/] " + units_2;
    series2.strokeWidth = 1;
    series2.tensionX = 0.8;
    series2.name = columnName_2;
    series2.bullets.push(new am4charts.CircleBullet());

    //                                                      //Creando la serie 3 - PRESIÓN
    let series3 = xyLineChart.series.push(new am4charts.LineSeries());
    series3.dataFields.dateX = "date";
    series3.dataFields.valueY = "status";
    series3.tooltipText = columnName_3 + ": [bold]{valueY}[/] " + units_3;
    series3.strokeWidth = 1;
    series3.tensionX = 0.8;
    series3.name = columnName_3;
    series3.bullets.push(new am4charts.CircleBullet());

    //                                                      //Creando la serie 3 - PRESIÓN
    let series4 = xyLineChart.series.push(new am4charts.LineSeries());
    series4.dataFields.dateX = "date";
    series4.dataFields.valueY = "potency";
    series4.tooltipText = columnName_4 + ": [bold]{valueY}[/] " + units_4;
    series4.strokeWidth = 1;
    series4.tensionX = 0.8;
    series4.name = columnName_4;
    series4.bullets.push(new am4charts.CircleBullet());
  */

    xyLineChart.cursor = new am4charts.XYCursor();

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    xyLineChart.scrollbarX = scrollbarX;
  };

  /**
   * Carga la gráfica con todo su contenido.
   */
  const loadChart = () => {
    chart.current = xyLineChart;
    createChart();

    return () => {
      xyLineChart.dispose();
    };
  };

  // const showData = thingsHistory.map((history) => (
  //   <li key={history._id}>
  //     {history.pressure} - {history.thing} - {history.creationDate}
  //   </li>
  // ));

  /*
  function reloadData() {
    setHideChart(true);
    // loadChart()
    console.log("reloadData - Data:"+data.length+"hideChart:"+hideChart);
    // thingsHistory.map((history) => console.log(history.pressure));
    // createChart();
    // xyLineChart.data = createData();
    // chart.current = xyLineChart;
    // return () => {
    // xyLineChart.dispose();
    // };
  }*/

  /**
   * Se crea este timer para darle un delay para que pueda
   * cargar y mostrar los datos de la gráfica ya que si no,
   * no los muestra por alguna razón.
   */
  /*const runTimer = () => {
    const timer = setTimeout(() => {
      // thingsHistory.map((history) => console.log(history.pressure))
      // xyLineChart.dispose();
      // reloadData();
      setHideChart(true);
    }, 1500);
    return () => clearTimeout(timer);
  };*/

  useEffect(() => {
    //                                                      //Una vez que se actualice  verdadero se mostrara la gráfica
    // if (hideChart) {
    loadChart();
    // }

    // runTimer();
    // eslint-disable-next-line
  }, [hideChart]);

  return (
    <div className="card-body">
      {/* <button onClick={reloadData}>Mostrar gráfica</button> */}
      {/* { hideChart ?  <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div> : null} */}
      {/* {hideChart ? null : <ul>{showData}</ul>} */}
      {/* {hideChart ? null : <h5>Cargando gráfica...</h5>} */}

      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      {/* {showChart} */}
    </div>
  );
};

export default ChartamLine;
