import React, { Fragment, useEffect, useContext } from "react";
import LogoutModal from "./LogoutModal";
import ProfileModal from "./ProfileModal";
import AuthContext from "../../context/auth/authContext";

const Topbar = () => {
  //                                                        //Extraer la informaci´ón de autenticación
  const authContext = useContext(AuthContext);
  const { user, userAuthenticated } = authContext;

  useEffect(() => {
    // userAuthenticated();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {/* Topbar */}
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">
          {/* División */}
          <div className="topbar-divider d-none d-sm-block"></div>
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user ? `${user.name} ${user.lastName}` : null}
              </span>
              <img
                className="img-profile rounded-circle"
                src={process.env.PUBLIC_URL + "/default-user-img.jpg"}
                alt="user-img"
              ></img>
            </a>
            {/* Dropdown - User Information */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {/* <a
                href="/#"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#profileModal"
              >
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Perfil
              </a> */}
              <a
                href="/#"
                className="dropdown-item"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cerrar sesión
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <ProfileModal/>
      <LogoutModal />
    </Fragment>
  );
};

export default Topbar;
